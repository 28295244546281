import React, { FC, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { Form } from "antd";
import DeviceListFilter from "./DeviceList.filter";
import DeviceListing from "./DeviceList.listing";
import DeviceListPagination from "./DeviceList.pagination";
import DeviceViewController from "../View/DeviceView.controller";
import DeviceFormController from "../Form/DeviceForm.controller";
import { useCrudListService } from '../../../../../hooks/crud/useCrudListService';
import { DeviceApi } from '../../../../../api';
import GatewayAssignFormController from '../Block/GatewayAssignForm.controller';

const initialState = {
    search: '',
    entity: {},
    entityId: null,
    listData: [],
    filters: {
        organization_id: null,
        organogram_id: null,
    },
    pagination: {
        currentPage: 1,
        pageSize: 10,
    },
    totalCount: 0,
    selectedRowKeys: [],
    sort: 'id desc',
    view: null,
    loading: false,
    isShowView: false,
    isShowForm: false,
    isShowPartialUpdateForm: false,
    fields: {},
    bulkAction: {
        action: '',
        field: '',
        value: '',
        ids: [] as any,
    },
    message: {
        network_error: 'A network error occurred. Please try again later.',
        delete_success: 'Delete icon successfully.',
        delete_confirm_title: 'Delete Device',
        delete_confirm: 'Are you sure you want to delete this icon?',
        delete_bulk_select: 'Please select item(s)',
        delete_bulk_confirm: 'Are you sure you wish to delete selected icon?',
    }
}

const DeviceListController: FC<any> = props => {
    const location = useLocation();
    const queryParams = parse(location.search);

    const queryState = {
        filters: {
            organization_id: queryParams?.organization_id || initialState.filters.organization_id,
            organogram_id: queryParams?.organogram_id || initialState.filters.organogram_id,
        }
    }

    const { BaseCrudListService, formRef, payload, setFilters, initialValues, listData, search, filters, sort, pagination, totalCount, loading,
        entityId, isShowView, isShowForm, setIsShowForm, isShowPartialUpdateForm, selectedRowKeys, bulkAction, reloadListing, reloadView, reloadForm } = useCrudListService(DeviceApi, queryState, initialState, props);

    useEffect(() => {
        initData();
    }, [search, filters, sort, pagination, reloadListing])

    useEffect(() => {
        handleUrl();
    }, [entityId, isShowView, isShowForm, isShowPartialUpdateForm])

    useEffect(() => {
        if (bulkAction.action !== '') {
            executeBulkAction();
        }
    }, [bulkAction])

    const initData = async () => {
        await handleUrl();
        await handlePayload();
        await loadData();
    }

    const loadData = (): Promise<any> => {
        return BaseCrudListService.loadData();
    }

    const executeBulkAction = (): Promise<any> => {
        return BaseCrudListService.executeBulkAction();
    };

    const handleUrl = (): void => {
        let urlObject: any = {}

        if (search) {
            urlObject.q = search
        }
        if (filters.organization_id) {
            urlObject.organization_id = filters.organization_id
        }
        if (filters.organogram_id) {
            urlObject.organogram_id = filters.organogram_id
        }
        BaseCrudListService.handleUrl(urlObject);
    }

    const processFilters = (): string => {
        let filterString = "1=1";

        if (filters.organization_id) {
            filterString += " AND organization_id='" + filters.organization_id + "'";
        }
        if (filters.organogram_id) {
            filterString += " AND organogram_id='" + filters.organogram_id + "'";
        }

        return BaseCrudListService.processFilters(filterString);
    }

    const processQueryParams = () => {
        let filterString = {};
        return BaseCrudListService.processQueryParams(filterString);
    }

    const processOrderBy = (): string => {
        let orderByString = '';
        return BaseCrudListService.processOrderBy(orderByString);
    }

    const handlePayload = (): void => {
        payload.current = {
            '$select': '',
            '$search': search,
            '$filter': processFilters(),
            '$queryParams': processQueryParams(),
            '$expand': '',
            '$orderby': processOrderBy(),
            '$top': pagination.pageSize,
            '$skip': pagination.pageSize * (pagination.currentPage - 1),
        }
    }

    const onChangeSwitchToggle = (checked: any, record: any) => {
        BaseCrudListService.onChangeSwitchToggle(checked, record);
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        BaseCrudListService.handleTableChange(pagination, filters, sorter, extra);
    }

    const handleOnChanged = (fieldName: string, value: any, text?: any) => {
        if (fieldName === 'filter_organization') {
            setFilters({
                ...filters,
                organization_id: value,
            });
        }
        if (fieldName === 'filter_organogarm') {
            setFilters({
                ...filters,
                organogram_id: value,
            });
        }
        BaseCrudListService.handleOnChanged(fieldName, value, text);
    }

    const handleCallbackFunc = (event: any, action: string, recordId?: any, data?: any) => {
        BaseCrudListService.handleCallbackFunc(event, action, recordId, data);
    }

    return (
        <div className="card">
            <Form form={formRef} name="exampleListingFilterForm" initialValues={initialValues} >
                <DeviceListFilter
                    filters={filters}
                    handleOnChanged={handleOnChanged}
                    handleCallbackFunc={handleCallbackFunc}
                />
                <DeviceListing
                    loading={loading}
                    listData={listData}
                    reloadListing={reloadListing}
                    selectedRowKeys={selectedRowKeys}
                    onChangeSwitchToggle={onChangeSwitchToggle}
                    handleOnChanged={handleOnChanged}
                    handleTableChange={handleTableChange}
                    handleCallbackFunc={handleCallbackFunc}
                />
                <DeviceListPagination
                    pagination={pagination}
                    totalCount={totalCount}
                    handleOnChanged={handleOnChanged}
                />
            </Form>
            {/* <DeviceViewController entityId={entityId} reloadView={reloadView} isShowView={isShowView} handleCallbackFunc={handleCallbackFunc} /> */}
            {/* <DeviceFormController entityId={entityId} reloadForm={reloadForm} isShowForm={isShowForm} handleCallbackFunc={handleCallbackFunc} /> */}
            {/* <GatewayAssignFormController entityId={entityId} reloadForm={reloadForm} isShowPartialUpdateForm={isShowPartialUpdateForm} handleCallbackFunc={handleCallbackFunc} /> */}
        </div>

    );
}

export default DeviceListController;
